import './hero.scss';
import ScrollReveal from "scrollreveal";
import Swiper from '../swiper/swiper'
import './hero-canvas'

if (S.first('.js-hero-swiper')) {
    initHeroSwiper()
}

function initHeroSwiper() {
    let activeIndex = 1
    let pagination = {
        activeElm: S.first('.hero__nums .hero__num'),
        totalElm: S.first('.hero__num--last'),
        index: activeIndex,
        total: ''
    }

    pagination.totalElm.innerHTML = S.find('.js-hero-swiper .swiper-slide').length


    let heroSw = Swiper('.js-hero-swiper', {
        speed: 1000,
        loop: false,
        autoplay: {
            delay: 3000,
        },
        // autoHeight: true,
        navigation: {
            nextEl: '.hero .hero__next',
            prevEl: '.hero .hero__prev',
        },
        on: {
            slideChange: function () {
                pagination.activeElm.innerHTML = this.realIndex + 1
            },
            init: function () {
                // console.log(this);
                pagination.total = this.slides.length
                pagination.activeElm.innerHTML = pagination.index;
            },
        }
    })
}

// var heroTitle = document.querySelector('.first-word');

// ScrollReveal().reveal(heroTitle, {
//     interval: 50000,
//     delay: 1000,
//     duration: 6000,
//     beforeReveal: function (e) {
//         e.classList.add("scroll-show")
//     }
// })