import './form.scss'
import './form.custom.scss'

import '../../js/input'
import './upload-file'
import './textarea'



// Validation
function Validation(parent, options) {
    var parent = S.find(parent);

    var defaults = {
        formBtn: S.find(parent, '.js-form-submit'),
        preloader: false,
        offset: 0,
        scroll: true,
        speed: 1000,
        checkOnInput: false,
        onComplete: function () {}
    }


    var params = Object.assign({}, defaults, options);



    var emailRegExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var required = S.find(parent, '[required]')
    var groupRequired = S.find(parent, '[data-group-required]')
    var result = [];
    var ifSucces = [];
    var succesValue = false;

    /**
     * Live input validation when user cahnge value in input
     * else
     * Check all form with all inputs
     */
    if (params.checkOnInput) {
        required.on('input', checkInput)
    } else {
        checkInputs()
    }



    function checkInputs() {

        // cheking all inputs that have required
        for (var i = 0; i < required.length; i++) {
            var arr = [];

            // cheking empty. Ecception: input[type="file"]
            if (S.attr(required[i], 'type') !== 'file') {
                arr.push(checkEmpty(required[i]))
            }

            // cheking the Email
            if (S.attr(required[i], 'type') === 'email') {
                arr.push(checkEmail(required[i]))
            }

            // cheking minimal symbols in string
            if (S.attr(required[i], 'data-min')) {
                arr.push(checkMin(required[i]))
            }

            // cheking maximal symbols in string

            if (S.attr(required[i], 'data-max')) {
                arr.push(checkMax(required[i]))
            }

            // cheking if checkbox is cheked
            if (S.attr(required[i], 'type') == 'checkbox') {
                arr.push(checkCheckbox(required[i]))
            }

            // One lowercase letter
            if (S.attr(required[i], 'data-lowercase') != null) {
                arr.push(checkLowercase(required[i]))
            }

            // One uppercase letter
            if (S.attr(required[i], 'data-uppercase') != null) {
                arr.push(checkUppercase(required[i]))
            }

            // One number exist
            if (S.attr(required[i], 'data-number') != null) {
                arr.push(checkNumberExist(required[i]))
            }

            // Compare with equal input
            if (S.attr(required[i], 'data-equal') != null) {
                let compareValue = S.val(S.attr(required[i], 'data-equal'))
                arr.push(checkEqual(required[i], compareValue))
            }

            // Compare input with files
            if (S.attr(required[i], 'type') === 'file') {
                arr.push(checkFileExist(required[i]))
            }

            // adding object with the containing results
            result.push({
                elm: required[i],
                value: arr.indexOf(false) == -1 ? true : false
            })

            // adding in mass to check if all inputs pass validation
            ifSucces.push(result[i].value)

            // adding error class to inputs that didnt pass valid
            let formGroup = S.closest(required[i], '.form__group')
            if (arr.indexOf(false) == -1) {
                S.removeClass(formGroup, 'error');
            } else {
                S.addClass(formGroup, 'error');
            }
        }

        // Check group of checboxes or radios
        if (groupRequired.length > 0) {
            groupRequired.forEach(groupParent => {
                ifSucces.push(checkGroup(groupParent))
            })
        }

        params.onComplete()

        // Scroll to error
        if (params.scroll) {
            scrollOnError()
        }

        succesValue = ifSucces.indexOf(false) == -1 ? true : false

    }

    function checkInput() {
        var arr = [];
        var errors = {};
        arr.push('empty: ' + checkEmpty(this))
        errors.empty = checkEmpty(this)


        // cheking the Email
        if (S.attr(this, 'type') === 'email') {
            arr.push(checkEmail(this))
            errors.email = checkEmail(this)
        }

        // cheking minimal symbols in string

        if (S.attr(this, 'data-min')) {
            arr.push('min: ' + checkMin(this))
            errors.min = checkMin(this)
        }

        // cheking maximal symbols in string
        if (S.attr(this, 'data-max')) {
            arr.push(checkMax(this))
            errors.max = checkMax(this)
        }

        // One lowercase letter
        if (S.attr(this, 'data-lowercase') != null) {
            arr.push('lowercase: ' + checkLowercase(this))
            errors.lowercase = checkLowercase(this)
        }

        // One uppercase letter
        if (S.attr(this, 'data-uppercase') != null) {
            arr.push('uppercase: ' + checkUppercase(this))
            errors.uppercase = checkUppercase(this)
        }

        // One number exist
        if (S.attr(this, 'data-number') != null) {
            arr.push('number: ' + checkNumberExist(this))
            errors.number = checkNumberExist(this)
        }

        // Compare with old password
        if (S.attr(this, 'data-compare') != null) {
            let compareValue = S.val(S.attr(this, 'data-compare'))
            arr.push('compare: ' + checkCompare(this, compareValue))
            errors.compare = checkCompare(this, compareValue)
        }

        // Compare with equal input
        if (S.attr(this, 'data-equal') != null) {
            let compareValue = S.val(S.attr(this, 'data-equal'))
            arr.push('equal: ' + checkEqual(this, compareValue))
        }

        // adding object with the containing results
        result.push({
            elm: this,
            value: arr.indexOf(false) == -1 ? true : false
        })

        // adding in mass to check if all inputs pass validation
        ifSucces.push(result.value)

        // adding error class to inputs that didnt pass valid
        let formGroup = S.closest(this, '.form__group')
        if (arr.indexOf(false) == -1) {
            S.removeClass(formGroup, 'error');
        } else {
            S.addClass(formGroup, 'error');
        }

        params.onComplete(errors)
    }

    function setSuccess() {
        S.addClass(parent, 'success')

        setTimeout(function () {
            S.removeClass(parent, 'success')
            clearInputs()
        }, 2000);

        stopPreload()

    }

    function clearInputs() {
        parent[0].reset()
        S.removeClass(S.find(parent, '.active'), 'active')
    }

    function scrollOnError() {
        let firstError = S.find(parent, '.error')[0]

        if (!firstError) {
            return
        }

        // Add focus to first input element
        setTimeout(() => {
            S.find(firstError, 'input')[0].focus()
        }, 500);

        // Scroll to first error
        S.scrollTo(window, {
            top: firstError,
            offsetY: 100
        })
    }


    // validation functions
    function checkEmpty(elem) {
        var str = S.val(elem)

        str.replace(/^\s\s*/, '').replace(/\s\s*$/, '');

        if (S.val(elem).length <= 0) {
            return false
        }
        return true
    }

    function checkEmail(elem) {
        var text = S.val(elem)
        if (emailRegExp.test(text)) {
            return true
        }
        return false
    }

    function checkMin(elem) {
        if (S.val(elem).length <= S.attr(elem, 'data-min') - 1) {
            return false
        }
        return true
    }

    function checkMax(elem) {
        if (S.val(elem).length > S.attr(elem, 'data-max')) {
            return false
        }
        return true
    }

    function checkLowercase(elem) {
        return /[a-z]/.test(S.val(elem))
    }

    function checkUppercase(elem) {
        return /[A-Z]/.test(S.val(elem))
    }

    function checkNumberExist(elem) {
        return /[0-9]/.test(S.val(elem))
    }

    function checkCompare(elem, val) {
        return S.val(elem) !== val
    }

    function checkEqual(elem, val) {
        return S.val(elem) === val
    }

    function checkCheckbox(elem) {
        if (S.prop(elem, 'checked')) {
            return true
        }

        return false
    }

    function checkGroup(group) {
        let inputs = S.find(group, 'input[type="checkbox"], input[type="radio"]')
        let results = []

        S.each(inputs, function () {
            results.push(S.prop(this, 'checked'))
        })

        if (results.indexOf(true) == -1) {
            S.addClass(group, 'error')
        } else {
            S.removeClass(group, 'error')
        }

        return results.indexOf(true) != -1
    }

    function checkFileExist(elem) {
        if (!elem.filesArr) {
            return false
        }

        return elem.filesArr.length > 0
    }



    function startPreload() {
        S.addClass(params.formBtn, 'btn--preload').attr(params.formBtn, 'disabled', true)
        S.addClass(parent, 'form--preload')
    }

    function stopPreload() {
        S.removeClass(params.formBtn, 'btn--preload').attr(params.formBtn, 'disabled', false)
        S.removeClass(parent, 'form--preload')
    }

    return {
        isValid: succesValue,
        clearInputs: clearInputs,
        startPreload: startPreload,
        stopPreload: stopPreload,
        setSuccess: setSuccess
    }

}




if (!window.App) {
    window.App = {}
    window.App.Validation = Validation
} else {
    window.App.Validation = Validation
}