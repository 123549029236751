import './blog-page.scss';
var items = document.querySelector('.blog__items-all');
var windowWidth = document.body.offsetWidth;
if (document.querySelector(".blog__items-all")) {
    items.addEventListener('mouseenter', function (e) {
        if (e.target.classList == 'blog__item') {
            var item = e.target.querySelector('.blog__item-info');
            item.style.maxHeight = item.scrollHeight + 'px';
            if (windowWidth < 1024) {
                item.style.maxHeight = 0;
            }
        }

    }, true)
    items.addEventListener('mouseleave', function (e) {
        if (e.target.classList == 'blog__item') {
            var item = e.target.querySelector('.blog__item-info');
            item.style.maxHeight = 0;
        }
    }, true)
}