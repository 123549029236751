import './blog-item.scss';
import ScrollReveal from "scrollreveal";
var items = document.querySelector('#blog');
var windowWidth = window.innerWidth;

if (items) {
    items.addEventListener('mouseenter', function (e) {
        if (e.target.classList == 'blog__item' && windowWidth > 1024) {
            var item = e.target.querySelector('.blog__item-info');
            item.style.maxHeight = item.scrollHeight + 'px';
        }
    }, true)
    items.addEventListener('mouseleave', function (e) {
        if (e.target.classList == 'blog__item') {
            var item = e.target.querySelector('.blog__item-info');
            item.style.maxHeight = 0;
        }
    }, true)
}

var blogItem = document.querySelectorAll('.scroll-up');
for (var i = 0; i < blogItem.length; i++) {
    ScrollReveal().reveal(blogItem[i], {
        interval: 50000,
        delay: 1000,
        duration: 6000,
        beforeReveal: function (e) {
            e.classList.add("scroll-show")
        }
    })
}
// items.forEach(elm => {
//     elm.addEventListener('mouseenter', unfold);
//     elm.addEventListener('mouseleave', fold)
// })

// function unfold(e) {
//     var itemsArray = this.querySelector('.blog__item-info');
//     itemsArray.style.maxHeight = itemsArray.scrollHeight + 'px';
//     if (windowWidth < 1024) {
//         itemsArray.style.maxHeight = 0;
//     }
// }

// function fold(e) {
//     var itemsArray = this.querySelector('.blog__item-info');
//     itemsArray.style.maxHeight = 0;
// }