import './modal.scss'
import './modal.custom.scss'

// Custom Events
import initIframe from '../iframe/iframe';

class Modal {
    constructor(elm) {

        let self = this;
        this.modal = elm;
        this.openedModal = document.querySelector('.modal.open');
        this.iframe = null;
        this.allIframes = [];
        this.hasSwiper = false;
        this.openModalEvt = new CustomEvent('modalOpen')
        this.closeModalEvt = new CustomEvent('modalClose')
        this.clbks = {}

        this.modal.wsModal = this;

        this.modal.addEventListener('click', function (e) {
            e.stopPropagation()

            /**
             * If has swiper inside, then ignore click on wrapper and close modal.
             * Prevent closing modal when click on swiper items (next, prev, img, modal__media-wrapper)
             * else if has wrapper, then close modal only when click outsude .modal__wrapper
             */
            if (this.hasSwiper && e.target.closest('.modal__media-wrapper, img, .swiper-button-next, .swiper-button-prev')) {
                return
            } else if (!this.hasSwiper && e.target.closest('.modal__wrapper')) {
                return
            } else if (e.target.closest('.modal, .modal__close')) {
                self.closeModal(e)
            }
        })
    }

    openModal() {
        // Open modal
        this.modal.classList.add('open')

        // Get modal params
        this.hasSwiper = this.modal.querySelector('.swiper-container')
        this.openedModal = this.modal;
        this.allIframes = [...this.modal.querySelectorAll('iframe')]
        this.iframe = this.modal.querySelector('.swiper-container') != null ?
            this.modal.querySelector('.swiper-slide:first-child iframe') :
            this.modal.querySelector('iframe');

        // Start iframe
        if (this.iframe) {
            this.startIframe()
        }

        document.dispatchEvent(this.openModalEvt)

        if (this.clbks.openModal) {
            this.clbks.openModal()
        }
    }

    closeModal() {

        // Close modal
        this.modal.classList.remove('open')

        // Pause iframe
        if (this.allIframes.length > 0) {
            this.pauseIframe()
        }

        document.dispatchEvent(this.closeModalEvt)

        if (this.clbks.closeModal) {
            this.clbks.closeModal()
        }
    }

    startIframe() {
        initIframe(this.iframe)
    }

    pauseIframe() {
        // Loop through iframes and pause it
        this.allIframes.forEach(iframe => {
            iframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*')
        })
    }

    on(method, clbk) {
        if (!S.isFunction(clbk)) {
            throw 'Modal.on -> Second argument [clbk] must be Function'
        }
        this.clbks[method] = clbk
    }
}

initModals()

function initModals() {
    S.each('.modal', function () {
        // Create class if NOT inited.
        if (!this.wsModal) {
            new Modal(this)
        }
    })
}

// Make global window.App.initModals
S.makeGlobal('initModals', initModals)


// Events
let target;
document.body.addEventListener('click', function (e) {
    target = e.target.closest('[data-modal]')
    if (target) {
        let selector = target.dataset.modal
        document.querySelector(selector).wsModal.openModal()
    }
})