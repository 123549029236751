// CSS
import './vendors/swiper.min.scss'
import './swiper.scss'


// JS
import Swiper from './vendors/swiper.min'
import initIframe from '../iframe/iframe';

export default function (selector, options) {
    // Create swiper instance
    let sw = new Swiper(selector, options)
    if (Array.isArray(sw)) {
        sw.forEach(swItem => {
            setIframeVideo(swItem)
        })
    } else {
        setIframeVideo(sw)
    }

    return sw
}

function setIframeVideo(sw) {
    let iframes, videos;

    /**
     * If swiper has iframe inside, then we need to pause or play video
     */

    // 1. Detect if swiper has iframes
    // iframes = [...sw.$el[0].querySelectorAll('iframe')]
    // videos = [...sw.$el[0].querySelectorAll('video')];

    // If has iframes
    if (iframes || videos) {
        // 2. Add onSlideChange event
        sw.on('slideChange', function () {
            // 3. Get active slide iframe
            let activeSlideIframe = this.slides[this.activeIndex].querySelector('iframe')
            let activeSlideVideo = this.slides[this.activeIndex].querySelector('video')

            // 4. Pause all iframes
            if (iframes) {
                iframes.forEach(iframe => {
                    iframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*')
                })
            }

            if (videos) {
                videos.forEach(video => {
                    video.pause()
                })
            }


            // 5. Init or Play iframe
            if (activeSlideIframe) {
                initIframe(activeSlideIframe)
            }

            // 5. Init or Play iframe
            if (activeSlideVideo) {
                activeSlideVideo.play()
            }
        })
    }
}

document.addEventListener('click', function (e) {
    if (e.target.closest('[data-swipe-to]')) {
        let swiperId = e.target.getAttribute('data-swiper')
        let swiperElm = document.querySelector(swiperId)
        let index = e.target.getAttribute('data-swipe-to')
        swiperElm.swiper.slideTo(index)
    }
})