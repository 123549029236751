import './button.scss'
import './button.custom.scss'

// Disabled buttons
document.addEventListener('click', function (e) {
    if (e.target.closest('.btn--disabled')) {
        e.preventDefault()
        e.stopPropagation()
        return false
    }
})

// Place for animation

