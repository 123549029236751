import './dropdown.scss'
import './dropdown.custom.scss'

// JS

(function () {
    let dropdownBtn = S.find('.has-dropdown');
    S.on(dropdownBtn, 'click', toggleDropdown)

    function toggleDropdown(e) {
        e.stopPropagation();
        
        let nextDropdown = S.find(this, '.dropdown')[0]
        let nextDropdownScrollHeight = nextDropdown.scrollHeight        
        

        let hasDropdown = S.find(this)
        let clickedOnIcon =  S.find(e.target)[0].closest('.dropdown__icon') > 0 || S.hasClass(e.target, 'dropdown__icon')

        if ( clickedOnIcon == false ) {
            if (S.hasClass(hasDropdown, 'open')) {
                S.removeClass(hasDropdown, 'open')
                nextDropdown.style.maxHeight = nextDropdownScrollHeight + 'px'
                setTimeout(() => {
                    nextDropdown.style.maxHeight = '0px'
                }, 250);
            } else {
                S.addClass(hasDropdown, 'open')
                nextDropdown.style.maxHeight = nextDropdownScrollHeight + 'px'
                nextDropdown.addEventListener('transitionend', removeStyles)
            }
        } else {
            if ( S.hasClass(this, 'open') ) {
                nextDropdown.style.maxHeight = nextDropdownScrollHeight + 'px'
                setTimeout(() => {
                    nextDropdown.style.maxHeight = '0px'
                }, 250);
            } else {
                nextDropdown.style.maxHeight = nextDropdownScrollHeight + 'px'
                nextDropdown.addEventListener('transitionend', removeStyles)
            }

            let hasDropdownElm = S.find(e.target)[0].closest('.has-dropdown')
            S.toggleClass(hasDropdownElm, 'open')
        }
    }

    function removeStyles() {
        this.style.maxHeight = 'none'
        this.removeEventListener('transitionend', removeStyles)
    }
})()