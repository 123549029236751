let allInputs = S.find('input, textarea');

checkInputs();
S.on(allInputs, 'change', checkInputs)


function checkInputs() {
    S.each(allInputs, function () {
        if (this.value != '') {
            S.addClass(this, 'active')
        } else {
            S.removeClass(this, 'active')
        }
    })
}