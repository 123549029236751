import './contact.scss';
import ScrollReveal from "scrollreveal";
var topBtn = document.querySelector(".scrolled");
topBtn.addEventListener('click', focusedScroll)

function focusedScroll() {
    var contactBlock = document.querySelector('#contact');
    var topPos = contactBlock.offsetTop;
    document.querySelector('#contact').scrollTop = topPos;
    document.querySelector('#focused').focus();

}
var contactForm = document.querySelector('.block__contact-form');
ScrollReveal().reveal(contactForm, {
    delay: 1000,
    beforeReveal: function (e) {
        e.classList.add("scroll-show")
    }
})
var addressItem = document.querySelectorAll('.scroll-scale');
for (var i = 0; i < addressItem.length; i++) {
    ScrollReveal().reveal(addressItem[i], {
        delay: 1000,

        beforeReveal: function (e) {
            e.classList.add("scroll-show")
        }
    })
}