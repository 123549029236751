import throttle from 'lodash/throttle'
import debounce from 'lodash/debounce'
var unit = 380,
    canvas, ctx, height, width, xAxis, yAxis, draw;
var drawCanvas = true;


function init() {
    canvas = document.getElementById("helixCanvas");

    if (!canvas) {
        return
    }

    canvas.width = 2400;
    canvas.height = 800;
    ctx = canvas.getContext("2d");
    height = canvas.height;
    width = canvas.width;
    xAxis = 400;
    yAxis = 0;
    ctx.save();
    draw();
}

draw = function () {

    ctx.clearRect(0, 0, width, height);
    if (drawCanvas) {
        ctx.strokeStyle = "#000";
        // drawLines(draw.t);
        // draw.seconds = draw.seconds - rotationSpeed;
        // draw.t = draw.seconds * Math.PI;
        // setTimeout(draw, 35);
        drawSine(draw.t);
        drawSine(draw.t, true);
        drawLines(draw.t);
        draw.seconds = draw.seconds - 0.001;
        // draw.seconds = draw.seconds - 0.005;
        draw.t = draw.seconds * Math.PI;
    }
    requestAnimationFrame(draw)
};
draw.seconds = 0;
draw.t = 1;

function drawSine(t, isNegative = false) {
    ctx.strokeStyle = "#351C57";
    ctx.fillStyle = "#fff";
    ctx.lineWidth = 4;
    ctx.beginPath();


    var x = t;
    var y = isNegative ? Math.sin(-x) : Math.sin(x);

    ctx.moveTo(yAxis, unit * y + xAxis);
    // Loop to draw segments
    for (var i = yAxis; i <= width; i += 10) {
        x = t + (-yAxis + i) / unit * 1.45;
        y = isNegative ? Math.sin(-x) : Math.sin(x);
        ctx.lineTo(i, unit * y + xAxis);
    }
    ctx.stroke();
}


function drawLines(t) {
    ctx.fillStyle = "#fff";
    for (let i = yAxis; i <= width; i += 140) {
        ctx.lineWidth = 1;
        ctx.beginPath();

        var x = t + (-yAxis + i) / unit * 1.45;
        var y1 = unit * Math.sin(x) + xAxis;
        var y2 = unit * Math.sin(-x) + xAxis;

        ctx.moveTo(i, y1);
        ctx.lineTo(i, y2);
        ctx.stroke();
        drawCircles(i, x, y1, y2);
    }
}

function drawCircles(i, x, y1, y2) {
    ctx.lineWidth = 0;
    ctx.beginPath();
    ctx.arc(i, y1, 8 - Math.cos(x) * 3, 0, 2 * Math.PI);
    ctx.fillStyle = '#351C57'
    ctx.strokeStyle = '#fff'
    ctx.lineWidth = 5
    ctx.fill();
    ctx.stroke();


    ctx.beginPath();
    ctx.arc(i, y2, 8 + (Math.cos(x)) * 3, 0, 2 * Math.PI);
    ctx.fill();
    ctx.stroke();

    ctx.strokeStyle = '#351C57'
}

init()


if (document.getElementById("helixCanvas")) {
    resized()
    window.addEventListener('resize', throttle(resized, 1000));

    function resized() {
        var windowWidth = window.innerWidth;
        if (windowWidth < 1024) {
            drawCanvas = false;
        } else {
            drawCanvas = true;
        }
    }
}