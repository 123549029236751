import './history.scss';
import ScrollReveal from "scrollreveal";
// var bg = document.querySelector('.brand-letter');
var rev = ScrollReveal();
rev.reveal('.brand-letter', {
    origin: 'left',
    interval: 500,
    delay: 700,
    distance: '594px',
    easing: 'ease-in',

});
// ScrollReveal().reveal(".scroll-up", {
//     interval: 1000,
//     delay: 5000,
//     beforeReveal: function (e) {
//         e.classList.add("scroll-show")
//     }
// })
// if (bg) {
//     function scrollBack() {
//         window.addEventListener("scroll", scrollBack)
//         if (window.pageYOffset > bg.scrollHeight) {
//             bg.style.transform = 'translateX(0)'
//         }
//     }
//     scrollBack()
// ScrollReveal().reveal('.brand-letter', scrollBack);
// }