class UploadFile {
  constructor(parent) {
    this.parent = parent;
    this.input = this.parent.querySelector('input[type="file"]')
    this.uploadedContainer = this.parent.querySelector('.uploaded-files')
    this.arr = Array.from(this.input.files)

    this.input.addEventListener('change', this.addFiles.bind(this))
    this.uploadedContainer.addEventListener('click', this.removeFile.bind(this))


  }

  addFiles() {
    for (var i = 0; i < this.input.files.length; i++) {
      var fileNameString = this.input.files[i].name
      var fileNameStringArr = fileNameString.split('.')
      var fileNameStringEnd = fileNameStringArr[fileNameStringArr.length - 1]
      var fileNameStringBeginning, fileName
      if (fileNameStringArr[0].length > 11) {
        fileNameStringBeginning = fileNameString.slice(0, 10)
        fileName = fileNameStringBeginning + '...' + fileNameStringEnd
      } else {
        fileName = fileNameString
      }

      this.uploadedContainer.innerHTML += `
      <li data-index=${i}>
        <div class="form__upload-item">
          
          <span class="form__upload-file-name">${fileName}</span>

          <button class="btn btn--link btn-icon-only btn--nopadding js-remove-file" type="button">
              <img src="img/close.svg" class="icon--mleft" width="12" height="12" alt="">
          </button>
          
        </div>
      </li>`

      this.arr.push(this.input.files[i])
      this.input.filesArr = this.arr
    }

    /**
     * Input must be cleared
     * If we try to upload same file twice and input will be filled with that file,
     * we will have no effect
     */
    this.input.value = ''

  }

  removeFile(e) {
    var elm = S.closest(e.target, '.js-remove-file')

    if (elm) {
      var li = S.closest(elm, 'li')
      var i = S.index(li)
      S.remove(li)
      this.arr.splice(i, 1)
    }

    this.input.filesArr = this.arr
  }

  clearAllFiles() {
    this.uploadedContainer.innerHTML = ''

  }
}

// Get all forms with input[type="file"]
S.each('input[type="file"]', function () {
  let form = this.closest('.form')
  new UploadFile(form)
})