import './nav.scss'
import './nav.custom.scss'

// JS

(function () {
    let nav = document.querySelector('#nav');
    if (!nav) return // Exit if #nav doesn't exist

    let body = S.first('body')
    let html = S.first('html')

    let toggleBtn = nav.parentNode.querySelector('#nav__toggle');
    let closeBtn = nav.parentNode.querySelector('.js-nav-close');

    let navBG = nav.querySelector('.nav__bg')
    let navOpen = new CustomEvent('navOpen')
    let navClose = new CustomEvent('navClose')
    let isOpen = false;


    toggleBtn.addEventListener('click', toggleNav);
    navBG.addEventListener('click', toggleNav);
    closeBtn.addEventListener('click', closeNav);

    function toggleNav() {
        if (isOpen) {
            closeNav()
        } else {
            document.dispatchEvent(navOpen)
            nav.classList.add('open')
            toggleBtn.classList.add('open')
            body.classList.add('overflow-hidden')
            html.classList.add('overflow-hidden')
        }

        isOpen = !isOpen
    }

    function closeNav() {
        document.dispatchEvent(navClose)
        nav.classList.remove('open')
        toggleBtn.classList.remove('open')
        body.classList.remove('overflow-hidden')
        html.classList.remove('overflow-hidden')
    }
    let collapseMenu = document.querySelectorAll('[data-scroll]')
    for (let i = 0; i < collapseMenu.length; i++) {
        collapseMenu[i].addEventListener('click', closeMenu)
    }


    function closeMenu() {
        document.dispatchEvent(navClose)
        nav.classList.remove('open')
        toggleBtn.classList.remove('open')
        body.classList.remove('overflow-hidden')
        html.classList.remove('overflow-hidden')
    }
})()