import './iframe.scss';


function initIframe(iframe){
    
    if (iframe.getAttribute('src')) {
        iframe.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*')
    } else {
        let modalIframeSrc = iframe.getAttribute('data-src')
        let paused = iframe.getAttribute('data-paused')
        let enableJs = modalIframeSrc.includes('?') ? '&enablejsapi=1' : '?enablejsapi=1';
        
        if(modalIframeSrc.includes(enableJs)){
            modalIframeSrc += '&autoplay=1'
        }
        else {
            modalIframeSrc += enableJs + '&autoplay=1'
        }
        
        if (!paused) {
            iframe.setAttribute('src', modalIframeSrc)
        }
        
    }
}

export default initIframe;