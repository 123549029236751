import './article.scss';

var svgElementTop = document.querySelector('.feature__decor-top svg');
var svgElementBottom = document.querySelector('.feature__decor-bottom svg');
var elem__top = document.querySelector('.feature__decor-top')
var elem__bottom = document.querySelector('.feature__decor-bottom')

if (elem__top || elem__bottom) {


    document.addEventListener('scroll', function () {
        if (window.pageYOffset > 200) {
            svgElementTop.classList.add('svganimate')

        }
        if (window.pageYOffset > 400) {
            svgElementBottom.classList.add('svganimate')
        }
    })
}