// CSS
import '../scss/common.scss';

// Plugins
import ScrollReveal from "scrollreveal";
// import Tweenmax from './vendors/gsap/TweenLite.min'

// Components
import '../components/button/button'
import '../components/dropdown/dropdown'
import '../components/header/header'
import '../components/nav/nav'
// import '../components/iframe/iframe'
import '../components/modal/modal'
import '../components/icon/icon'
import '../components/form/form'
// import '../components/accordion/accordion'
// import '../components/tab/tab'
// import '../components/gallery/gallery'
import '../components/hero/hero'
import '../components/picture/picture'
import '../components/contact/contact'
import '../components/blog-item/blog-item'
import '../components/pagination/pagination'
import '../components/brands/brands'
import '../components/benefits/benefits'
import '../components/blog/blog'
import '../components/history/history'
import '../components/feature/feature'
// End: Components

// Pages
import '../pages/index/index'
import '../pages/404/404'
import '../pages/no-ie/no-ie'
import '../pages/article/article'
import '../pages/blog-page/blog-page'
// End: Pages

// Sections
import '../sections/footer/footer'
// End: Sections

// Add global App variable
if (!window.App) {
  window.App = {}
}