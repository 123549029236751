// CSS
import './header.scss'
import './header.custom.scss'

// JS
import '../nav/nav'
import throttle from 'lodash/throttle'

// Config
let config = {
    throttle: 30,
    scrollPosTiny: 70,
    scrollPosHidden: 600
}


// Class
class Header {
    constructor(options) {
        let self = this;
        this.header = S.find('#header');
        this.gradient = S.find('.header--gradient')
        this.lastScroll = 0;
        this.timer = null;
        this.isScrollDetect = false;
        this.navIsOpen = false
        this.def = {
            throttle: 100,
            scrollPosTiny: 70,
            scrollPosHidden: 300
        }

        this.params = Object.assign(this, this.def, options)

        this.checkScrollPos();

        S.on(window, 'scroll', throttle(this.scrollStart.bind(this), this.params.throttle))

        // Listen or custom events
        document.addEventListener('navOpen', () => {
            this.navIsOpen = true
        })
        document.addEventListener('navClose', () => {
            this.navIsOpen = false
        })
        document.addEventListener('modalOpen', this.hide.bind(this))
        document.addEventListener('modalClose', this.show.bind(this))
    }

    scrollStart() {
        this.isScrollDetect = true
        var st = window.pageYOffset
        this.changeHeaderVisibility(st)
    }

    checkScrollPos() {
        this.isScrollDetect = false
        var st = window.pageYOffset;
        this.changeHeaderVisibility(st)
    }

    changeHeaderVisibility(st) {
        // Show header
        if (st > this.lastScroll && st > this.params.scrollPosTiny) {

            if (!S.hasClass(this.header, 'header--tiny')) {
                S.addClass(this.header, 'header--tiny')
            }

            if (st > this.params.scrollPosHidden && this.isScrollDetect) {
                this.hide()
            }

        }
        // Hide header
        else if (st < this.lastScroll) {
            if (st < this.params.scrollPosTiny && S.hasClass(this.header, 'header--tiny')) {
                S.removeClass(this.header, 'header--tiny')
            }

            this.show()
        }

        this.lastScroll = st
    }

    hide() {
        if (this.navIsOpen) return

        if (!S.hasClass(this.header, 'is-hidden')) {
            S.addClass(this.header, 'is-hidden')
        }

    }

    show() {
        if (S.hasClass(this.header, 'is-hidden')) {
            S.removeClass(this.header, 'is-hidden')

        }
    }
}

// Init
let header = new Header(config)