import './feature.scss';
import ScrollReveal from "scrollreveal";
var svgElement = document.querySelector('.feature__decor svg');
var elem = document.querySelector('.feature__decor')
if (elem) {
    document.addEventListener('scroll', function () {
        if (window.pageYOffset > elem.scrollHeight) {
            svgElement.classList.add('svganimate')
        }
    })
}

var featureItem = document.querySelectorAll('.feature__item');
for (var i = 0; i < featureItem.length; i++) {
    ScrollReveal().reveal(featureItem[i], {
        interval: 50000,
        delay: 1000,
        duration: 6000,
        beforeReveal: function (e) {
            e.classList.add("scroll-show")
        }
    })
}