import './benefits.scss';
import ScrollReveal from "scrollreveal";
VanillaTilt.init(document.querySelectorAll('.tiltitem'), {
    glare: true,
    "max-glare": .5,
    scale: 1.05,
    gyroscope: false

})
let leafElm = document.getElementsByClassName('leaf')
for (let i = 0; i < leafElm.length; i++) {
    leafElm[i].addEventListener('animationend', function () {
        this.classList.remove('animate')
        // this.style.top = Math.floor((Math.random() * 100)) + '%'
        this.style.right = Math.floor((Math.random() * 100)) + '%'
        setTimeout(() => {
            this.classList.add('animate')
        }, 100)
    })
}
var benefitItem = document.querySelectorAll('.benefit__item');
for (let i = 0; i < benefitItem.length; i++) {
    ScrollReveal().reveal(benefitItem[i], {
        interval: 1000,
        delay: 1000,
        duration: 6000,
        beforeReveal: function (e) {
            e.classList.add("scroll-show")
        }
    })

}

// ScrollReveal().reveal(benefitItem[0], {
//     interval: 10000,
//     delay: 2000,
//     beforeReveal: function (e) {
//         e.classList.add("scroll-show")
//     }
// })
// ScrollReveal().reveal(benefitItem[2], {
//     interval: 20000,
//     delay: 3000,
//     beforeReveal: function (e) {
//         e.classList.add("scroll-show")
//     }
// })