import './brands.scss';
import Swiper from '../swiper/swiper'

Swiper('.js-swiper-carousel', {
    slidesPerView: 5,
    navigation: {
        nextEl: '.js-swiper-arrows .arrow-next',
        prevEl: '.js-swiper-arrows .arrow-prev',
    },

    breakpoints: {
        1366: {
            slidesPerView: 4,
            spaceBetween: 20,
        },
        1024: {
            slidesPerView: 3,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 2,
            spaceBetween: 30,
        },
        640: {
            slidesPerView: 1,
            spaceBetween: 20,
        },
        320: {
            slidesPerView: 1,
            spaceBetween: 10,
        }
    }

});
var swiperItem = document.querySelectorAll('brand__item');
for (var i = 0; i < swiperItem.length; i++) {
    // if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
    //     swiperItem[i].classList.add('touch');
    // }
    if (windowWidth < 1024) {
        swiperItem[i].classList.add('touch');
    }
}