import autosize from '../../../node_modules/autosize/dist/autosize.min.js'

autosize(S.find('textarea'));

// Textarea counter
let BACKSPACE = 8
S.find('.js-textarea-counter').forEach( elm => {
    let countHolder = S.find(S.closest(elm, '.form__group'), '.js-form-count')
    let startCount = +S.html(countHolder)
    let totalSymbols;

    S.on(elm, 'input', function (e) {
        totalSymbols = S.val(elm).replace(/\s/g, '').length

        if (totalSymbols >= startCount) {
            S.val(elm, getString(S.val(elm)))
        }
        
        S.html(countHolder, startCount - S.val(elm).replace(/\s/g, '').length)
        
        if (totalSymbols >= startCount) {
            S.html(countHolder, 0)
        }
    })

    function getString(str) {
        return str.substring(0, startCount + str.split(' ').length - 1)
    }
})
